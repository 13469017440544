import 'swiper/css';

import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import NextImage from '@/components/NextImage';

type CarouselProps = {
  bg: string;
  ltr: boolean;
  className: string;
  src: string[]; //minimum 4 photos
  isRotated: boolean;
};

export default function Carousel({
  bg,
  ltr,
  className,
  src,
  isRotated = true,
}: CarouselProps) {
  return (
    <div
      className={`h-full w-full ${className}`}
      data-aos='fade-up'
      data-aos-duration='1200'
    >
      <div className='relative flex items-center justify-center'>
        <NextImage
          width={3400}
          height={700}
          alt='film'
          src={bg}
          className='absolute z-0 h-full w-full'
        />
        <div
          className={`z-10 h-full w-full ${
            isRotated
              ? 'origin-center -rotate-[2.3deg] transform pt-[7.5%]'
              : 'relative mt-[14px] flex items-center justify-center pt-[4%] max-md:mt-1'
          }`}
        >
          <Swiper
            modules={[Autoplay]}
            spaceBetween={4}
            slidesPerView={3}
            loop={true}
            breakpoints={{
              768: {
                spaceBetween: 19,
              },
            }}
            autoplay={{
              delay: 0,
              reverseDirection: ltr,
              disableOnInteraction: false,
            }}
            speed={5000}
          >
            {src.map((src, index) => (
              <SwiperSlide key={index}>
                <NextImage
                  width={400}
                  height={200}
                  src={src}
                  alt={`image-${index}`}
                  className='h-full w-full'
                  imgClassName='w-full h-full'
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
