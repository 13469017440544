'use client';

import AOS from 'aos';
import * as React from 'react';
import { FaAnglesDown } from 'react-icons/fa6';

import NextImage from '@/components/NextImage';
import Typography from '@/components/Typography';
import clsxm from '@/lib/clsxm';

export default function JumbotronHomepage() {
  React.useEffect(() => {
    AOS.init({
      once: false,
    });
  }, []);

  const handleClick = () => {
    const element = document.getElementById('AboutSchematics');
    if (element) {
      let offset;
      if (window.innerWidth < 1024) {
        offset = element.getBoundingClientRect().top + window.scrollY - 80;
      } else {
        offset = element.getBoundingClientRect().top + window.scrollY - 107;
      }
      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      {/*desktop*/}
      <section
        className={clsxm(
          'relative hidden items-center overflow-hidden bg-typo-main',
          'md:flex md:h-[800px]',
          'xl:h-[900px]',
        )}
      >
        <div className='absolute z-0 h-full w-full'>
          <div className='relative flex h-1/2 '>
            <div className='h-full w-1/2 bg-nlc-main lg:h-[80%]'></div>
            <div className='h-full w-1/2 bg-reeva-main lg:h-[130%]'></div>
          </div>
          <div className='relative flex h-1/2 lg:h-full'>
            <div className='h-full w-1/2 bg-npc-main lg:absolute lg:-top-[90px]'></div>
            <div className='h-full w-1/2 bg-bst-main lg:absolute lg:-bottom-[70px] lg:right-0'></div>
          </div>
        </div>
        {/*kiri*/}
        <div className='absolute left-0 z-10 ml-auto mt-auto flex w-1/2 flex-col items-center justify-center'>
          <NextImage
            src='/homepage/PensilLampu.png'
            alt='pensil & lampu'
            width={314}
            height={406}
            className='absolute z-10 flex md:left-[90px] md:top-[-400px] md:w-[45%] lg:left-[50px] lg:top-[-475px] lg:w-[45%] xl:top-[-590px] xl:w-[85%]'
            data-aos='fade-down-right'
            data-aos-delay={300}
            data-aos-duration={600}
          />
          <NextImage
            src='/homepage/Kartu.png'
            alt='kartu NLC'
            width={142}
            height={123}
            className='absolute z-10 md:left-[160px] md:top-[-140px] md:w-[20%] lg:left-[80px] lg:top-[-230px] lg:w-full xl:left-[140px]'
            data-aos='fade-down-right'
            data-aos-delay={300}
            data-aos-duration={600}
          />
          <NextImage
            src='/homepage/Pc.png'
            alt='Pc'
            width={338}
            height={356}
            className='absolute z-10 mb-auto w-full overflow-y-hidden md:bottom-[-400px] md:w-[40%] lg:bottom-[-490px] lg:left-[100px] lg:w-full xl:left-[160px]'
            data-aos='fade-up-right'
            data-aos-delay={500}
            data-aos-duration={600}
          />
          <NextImage
            src='/homepage/JamTangan.png'
            alt='JamTangan'
            width={209}
            height={356}
            className='absolute left-0 z-10 mb-auto w-full overflow-y-hidden md:bottom-[-400px] md:w-[30%] lg:bottom-[-450px]'
            data-aos='fade-up-right'
            data-aos-delay={500}
            data-aos-duration={600}
          />
        </div>

        {/*kanan*/}
        <div className='absolute right-0 z-10 flex w-1/2 flex-1 items-center justify-end max-md:w-screen'>
          <NextImage
            src='/homepage/Mic.png'
            alt='Mic'
            width={239}
            height={356}
            className='absolute z-10 md:bottom-[-400px] md:max-w-[30%] lg:bottom-[-400px] lg:right-0 xl:bottom-[-450px]'
            data-aos='fade-up-left'
            data-aos-delay={700}
            data-aos-duration={600}
          />
          <NextImage
            src='/homepage/Cangkir.png'
            alt='cangkir'
            width={269}
            height={256}
            className='absolute z-20 md:bottom-[-400px] md:right-[190px] md:w-[40%] lg:right-[160px] lg:w-[60%] xl:bottom-[-450px] xl:right-[280px]'
            data-aos='fade-up-left'
            data-aos-delay={700}
            data-aos-duration={600}
          />
          <NextImage
            src='/homepage/bayangan-ijo.png'
            alt='shadow ijo bawah kanan'
            width={409}
            height={356}
            className='absolute z-10 md:bottom-[-400px] md:right-[210px] md:w-[40%] lg:right-[200px] lg:w-[60%] xl:bottom-[-450px] xl:right-[380px]'
            data-aos='fade-up-left'
            data-aos-delay={700}
            data-aos-duration={600}
          />
          <NextImage
            src='/homepage/Jam.png'
            alt='jam bst'
            width={174}
            height={174}
            className='absolute z-10 md:bottom-[-220px] md:right-[180px] md:max-w-[20%] lg:bottom-[-270px] lg:right-[-240px] lg:min-w-full xl:bottom-[-340px] xl:right-[-320px]'
            data-aos='fade-up-left'
            data-aos-delay={700}
            data-aos-duration={600}
          />
          <NextImage
            src='/homepage/KasetPlay.png'
            alt='musicplayer'
            width={549}
            height={356}
            className='absolute z-10 md:bottom-[25px] md:right-0 md:max-w-[90%] lg:right-6 lg:top-[-530px] lg:max-w-[75%]'
            data-aos='fade-down-left'
            data-aos-delay={900}
            data-aos-duration={600}
          />
          <NextImage
            src='/homepage/Piano.png'
            alt='piano'
            width={60}
            height={100}
            className='absolute right-0 z-10 md:bottom-[25px] md:max-w-[8.5%] lg:top-[-460px]'
            data-aos='fade-down-left'
            data-aos-delay={900}
            data-aos-duration={600}
          />
        </div>
        <NextImage
          src='/homepage/TrpesiumBawah.png'
          alt='Schematics'
          width={420}
          height={500}
          className='absolute left-1/2 z-20 -translate-x-1/2 transform md:-bottom-2 md:h-[30%] md:max-w-[30%] xl:-bottom-1 xl:h-[28%]'
          imgClassName='h-full'
        />
        <div className='relative mx-auto flex w-[70%] flex-col md:z-20 md:w-[70%] md:translate-y-12'>
          <div
            className='relative z-20 items-center justify-center bg-gradient-rainbow py-2 md:rounded-3xl md:p-2'
            data-aos='fade-up'
            data-aos-duration={1000}
          >
            <div className='relative flex h-full flex-col items-center gap-2 rounded-2xl bg-typo-main max-md:gap-6 md:px-16 md:py-28'>
              <Typography
                variant='h4'
                weight='bold'
                font='LemonMilk'
                className={clsxm(
                  'text-center text-7xl text-typo-white',
                  'max-md:text-[24px]',
                  'md:pb-12 lg:text-6xl lg:leading-[70px]',
                  'xl:pb-16 xl:text-7xl xl:leading-[90px]',
                )}
                data-aos='zoom-in'
                data-aos-delay={800}
                data-aos-duration={800}
              >
                Schematics
                <br />
                2024
              </Typography>
              <div className='flex md:gap-8'>
                <div
                  className='flex-row-grow-0 flex items-center gap-3'
                  data-aos='fade-right'
                  data-aos-delay={1000}
                  data-aos-duration={600}
                >
                  <div className='rounded-full bg-bst-main md:h-3 md:w-3 xl:h-4 xl:w-4'></div>
                  <div className='rounded-full bg-nlc-main md:h-3 md:w-3 xl:h-4 xl:w-4'></div>
                  <div className='rounded-full bg-reeva-main md:h-3 md:w-3 xl:h-4 xl:w-4'></div>
                  <div className='rounded-full bg-npc-main md:h-3 md:w-3 xl:h-4 xl:w-4'></div>
                </div>
                <Typography
                  variant='h6'
                  weight='semibold'
                  font='Poppins'
                  className=' text-center text-2xl text-typo-white lg:text-xl xl:text-2xl'
                  data-aos='fade-down'
                  data-aos-delay={1000}
                  data-aos-duration={600}
                >
                  INOVASI EKSPLORATIF
                </Typography>
                <div
                  className='flex-row-grow-0 flex items-center gap-3'
                  data-aos='fade-left'
                  data-aos-delay={1000}
                  data-aos-duration={600}
                >
                  <div className='rounded-full bg-nlc-main md:h-3 md:w-3 xl:h-4 xl:w-4'></div>
                  <div className='rounded-full bg-reeva-main md:h-3 md:w-3 xl:h-4 xl:w-4'></div>
                  <div className='rounded-full bg-npc-main md:h-3 md:w-3 xl:h-4 xl:w-4'></div>
                  <div className='rounded-full bg-bst-main md:h-3 md:w-3 xl:h-4 xl:w-4'></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='flex flex-grow items-center justify-center md:mt-12 lg:mt-24'
            data-aos='fade-up'
            data-aos-duration={1100}
            data-aos-delay={200}
          >
            <button
              onClick={handleClick}
              className='flex items-center justify-center'
            >
              <FaAnglesDown className='animate-bounce text-5xl text-white lg:text-6xl' />
            </button>
          </div>
        </div>
      </section>

      {/*mobile*/}
      <section className='relative flex h-[768px] flex-wrap overflow-hidden bg-typo-main md:hidden'>
        <div className='absolute z-0 h-full w-full'>
          <div className='relative flex h-1/2'>
            <div className='h-full w-1/2 bg-nlc-main'></div>
            <div className='h-full w-1/2  bg-reeva-main'></div>
          </div>
          <div className='relative flex h-1/2'>
            <div className='h-full w-1/2 bg-npc-main'></div>
            <div className='h-full w-1/2 bg-bst-main'></div>
          </div>
        </div>

        <div className='relative h-1/2 w-1/2 overflow-hidden'>
          <NextImage
            src='/homepage/pensilMBL.png'
            alt='pensil'
            width={40}
            height={500}
            className='absolute left-8 top-[-60px] z-10 overflow-hidden'
            data-aos='fade-down-right'
            data-aos-delay={300}
            data-aos-duration={600}
          />
          <NextImage
            src='/homepage/KartuMBL.png'
            alt='kartu'
            width={78}
            height={68}
            className='absolute left-20 top-8 z-10 overflow-hidden'
            data-aos='fade-down-right'
            data-aos-delay={300}
            data-aos-duration={600}
          />
        </div>
        <div className='relative h-1/2 w-1/2 overflow-hidden'>
          <NextImage
            src='/homepage/KasetPlay.png'
            alt='musicplayer'
            width={290}
            height={300}
            className='absolute right-[-40px] top-[-40px] z-10 overflow-hidden'
            data-aos='fade-down-left'
            data-aos-delay={900}
            data-aos-duration={600}
          />
        </div>
        <div className='relative h-1/2 w-1/2 overflow-hidden'>
          <NextImage
            src='/homepage/Pc.png'
            alt='Pc'
            width={285}
            height={229}
            className='absolute bottom-0 left-[-100px]'
            data-aos='fade-up-right'
            data-aos-delay={500}
            data-aos-duration={600}
          />
          <NextImage
            src='/homepage/JamTangan.png'
            alt='JamTangan'
            width={102}
            height={134}
            className='absolute bottom-0 left-0'
            data-aos='fade-up-right'
            data-aos-delay={500}
            data-aos-duration={600}
          />
        </div>
        <div className='relative h-1/2 w-1/2 overflow-hidden'>
          <NextImage
            src='/homepage/Jam.png'
            alt='jam bst'
            width={54}
            height={44}
            className='absolute bottom-[240px] right-0 z-20'
            data-aos='fade-up-left'
            data-aos-delay={700}
            data-aos-duration={600}
          />
          <NextImage
            src='/homepage/Mic.png'
            alt='Mic'
            width={171}
            height={222}
            className='absolute bottom-0 right-[-40px] z-10 flex overflow-hidden'
            data-aos='fade-up-left'
            data-aos-delay={700}
            data-aos-duration={600}
          />
        </div>
        <NextImage
          src='/homepage/trpsmMB.png'
          alt='trapesum mobile'
          width={303}
          height={245}
          className='absolute -bottom-2 left-1/2 z-20 h-[30%] w-full max-w-[300px] -translate-x-1/2 transform'
        />

        <div className='absolute z-20 flex h-full w-full flex-col justify-end'>
          <div
            className='relative z-20 mx-auto w-[87%] max-w-[450px] items-center justify-center rounded-xl bg-gradient-rainbow p-1'
            data-aos='fade-up'
            data-aos-duration={1000}
          >
            <div className='relative flex h-full flex-col items-center gap-9 rounded-lg bg-typo-main px-9 py-24'>
              <Typography
                variant='h4'
                weight='bold'
                font='LemonMilk'
                className='text-center text-4xl leading-snug text-typo-white'
                data-aos='zoom-in'
                data-aos-delay={800}
                data-aos-duration={800}
              >
                Schematics
                <br />
                2024
              </Typography>
              <div className='flex flex-col items-center gap-6 py-2'>
                <div
                  className='flex-row-grow-0 flex items-center gap-3'
                  data-aos='fade-down'
                  data-aos-delay={1200}
                  data-aos-duration={600}
                >
                  <div className='h-3 w-3 rounded-full bg-bst-main'></div>
                  <div className='h-3 w-3 rounded-full bg-nlc-main'></div>
                  <div className='h-3 w-3 rounded-full bg-reeva-main'></div>
                  <div className='h-3 w-3 rounded-full bg-npc-main'></div>
                </div>
                <Typography
                  variant='h6'
                  weight='semibold'
                  font='Poppins'
                  className=' text-center text-base text-typo-white'
                  data-aos='zoom-in'
                  data-aos-delay={1000}
                  data-aos-duration={600}
                >
                  INOVASI EKSPLORATIF
                </Typography>
                <div
                  className='flex-row-grow-0 flex items-center gap-3'
                  data-aos='fade-up'
                  data-aos-delay={1200}
                  data-aos-duration={600}
                >
                  <div className='h-3 w-3 rounded-full bg-nlc-main'></div>
                  <div className='h-3 w-3 rounded-full bg-reeva-main'></div>
                  <div className='h-3 w-3 rounded-full bg-npc-main'></div>
                  <div className='h-3 w-3 rounded-full bg-bst-main'></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='mb-16 mt-20 flex items-center justify-center'
            data-aos='fade-up'
            data-aos-duration={1100}
            data-aos-delay={200}
          >
            <button
              onClick={handleClick}
              className='flex items-center justify-center'
            >
              <FaAnglesDown className='animate-bounce text-5xl text-white lg:text-6xl' />
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
