'use client';

import AOS from 'aos';
import * as React from 'react';
import { FaArrowRight } from 'react-icons/fa6';

import Carousel from '@/components/Carousel';
import ButtonLink from '@/components/links/ButtonLink';
import NextImage from '@/components/NextImage';
import Typography from '@/components/Typography';

export default function HomepageAbout() {
  React.useEffect(() => {
    AOS.init({
      once: false,
    });
  }, []);

  return (
    <section
      id='AboutSchematics'
      className='bg-typo-section mb-16 mt-24 h-full overflow-hidden py-8 md:mb-24'
    >
      <div className=' text-center'>
        <Typography
          variant='h3'
          weight='bold'
          font='LemonMilk'
          className=' text-typo-white max-lg:text-[28px]'
          data-aos='fade-up'
          data-aos-duration='1000'
        >
          ABOUT SCHEMATICS
        </Typography>
        <Typography
          variant='p'
          weight='regular'
          font='Poppins'
          className=' mx-auto px-8 py-6 text-typo-white md:px-24 lg:px-[200px]'
          data-aos='fade-up'
          data-aos-duration='1200'
        >
          Schematics merupakan salah satu event karya mahasiswa Teknik
          Informatika Institut Teknologi Sepuluh Nopember yang berfokus pada
          kompetisi pemrograman dan logika serta memperkenalkan teknologi kepada
          masyarakat luas dengan pendekatan yang terkini dan menarik melalui
          seminar dan pameran yang memikat
        </Typography>
        <ButtonLink
          variant='primary'
          size='md'
          href='/tentang-kami'
          data-aos='fade-up'
          data-aos-duration='1200'
        >
          <Typography
            variant='p'
            weight='medium'
            font='Poppins'
            className=' flex items-center gap-4 text-typo-main'
          >
            Learn More <FaArrowRight />
          </Typography>
        </ButtonLink>
      </div>
      <div className='relative h-full w-full'>
        <div className='z-20 mt-24 flex flex-col gap-1'>
          <div className='h-[2px] w-screen bg-[#51F5BA] md:h-1'></div>
          <div className='h-1 w-screen bg-[#06B0D2] md:h-2'></div>
          <div className='h-[6px] w-screen bg-[#9E62EB] md:h-3'></div>
          <div className='h-[2px] w-screen bg-[#FB5D5D] md:h-1'></div>
          <div className='h-1 w-screen bg-[#E3A245] md:h-2'></div>
        </div>
        <div className='absolute top-1/2 z-30 -translate-y-1/2 transform'>
          <div className='mb-10 flex w-screen justify-between max-md:hidden'>
            <NextImage
              src='/homepage/leftman.png'
              alt='Schematics 2024'
              width={300}
              height={215}
              className='z-20'
            />
            <NextImage
              src='/homepage/rightman.png'
              alt='Schematics 2024'
              width={300}
              height={215}
              className='z-20'
            />
          </div>
          <div className='mb-5 flex w-screen justify-between md:hidden'>
            <NextImage
              src='/homepage/leftman.png'
              alt='Schematics 2024'
              width={150}
              height={100}
              className='z-20'
            />
            <NextImage
              src='/homepage/rightman.png'
              alt='Schematics 2024'
              width={150}
              height={100}
              className='z-20 mt-[2px]'
            />
          </div>
        </div>
      </div>
      {/* <HomepageCarousel /> */}
      <Carousel
        bg='/homepage/filmroll.png'
        src={[
          '/homepage/img1.png',
          '/homepage/img2.png',
          '/homepage/img1.png',
          '/homepage/img2.png',
        ]}
        ltr={false}
        className='mb-[5%] mt-[14px] max-md:mt-1'
        isRotated={false}
      />
    </section>
  );
}
