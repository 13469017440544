'use client';

import AOS from 'aos';
import { useEffect, useState } from 'react';
import { BsArrowRightCircle } from 'react-icons/bs';

import UnstyledLink from '@/components/links/UnstyledLink';
import NextImage from '@/components/NextImage';
import Typography from '@/components/Typography';

export default function HomepageDesktopEvents() {
  const [hovered1, setHovered1] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [hovered3, setHovered3] = useState(false);
  const [hovered4, setHovered4] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);

  return (
    <main className='h-full bg-typo-main pb-24 pt-12 max-lg:hidden'>
      <div className=' text-center'>
        <Typography
          variant='h3'
          weight='bold'
          font='LemonMilk'
          className=' text-typo-white max-lg:text-[28px]'
        >
          EVENT PADA SCHEMATICS
        </Typography>
        <Typography
          variant='h5'
          weight='medium'
          font='Poppins'
          className=' text-typo-white '
        >
          Hover Karakternya untuk Melihat!!
        </Typography>
      </div>

      {/*NPC*/}
      <UnstyledLink href='/npc'>
        <div
          className='relative ml-auto mt-12 h-[150px] w-4/5 bg-[#FD6565]'
          onMouseEnter={() => setHovered1(true)}
          onMouseLeave={() => setHovered1(false)}
          data-aos='fade-left'
        >
          <div
            className={`absolute inset-0 ${hovered1 ? 'opacity-0' : 'opacity-100'} z-20 flex items-center justify-center transition-opacity`}
          >
            <NextImage
              src='/homepage/pisi.png'
              alt='NPC'
              width={210}
              height={150}
              className='absolute inset-0 z-20 mx-auto'
            />
            <Typography
              variant='h2'
              weight='bold'
              font='LemonMilk'
              className='z-10 text-[#4C1313] max-lg:text-[28px]'
            >
              NPC NPC NPC
            </Typography>
          </div>

          <div
            className={` ${hovered1 ? 'opacity-100' : 'opacity-0'} z-10 flex items-center justify-center transition-opacity`}
          >
            <div className='ml-auto flex h-[150px] w-full items-center justify-between bg-[#FD6565]'>
              <div className='flex items-center gap-6'>
                <NextImage
                  src='/homepage/hpisi.png'
                  alt='NPC'
                  width={281}
                  height={222}
                  className='h-full'
                />

                <Typography
                  variant='h5'
                  weight='bold'
                  font='LemonMilk'
                  className='text-[#4C1313]'
                >
                  NATIONAL PROGRAMMING CONTEST
                </Typography>
              </div>
              <div className='mr-24'>
                <Typography
                  variant='h4'
                  weight='bold'
                  font='LemonMilk'
                  className='cursor-pointer text-[#4C1313]'
                >
                  <BsArrowRightCircle className='animate-bounce' />
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </UnstyledLink>

      {/*BST*/}
      <UnstyledLink href='/bst'>
        <div
          className='relative mr-auto mt-6 h-[150px] w-4/5 bg-[#3BD09A]'
          onMouseEnter={() => setHovered2(true)}
          onMouseLeave={() => setHovered2(false)}
          data-aos='fade-right'
        >
          <div
            className={`absolute inset-0 ${hovered2 ? 'opacity-0' : 'opacity-100'} z-20 flex items-center justify-center transition-opacity`}
          >
            <NextImage
              src='/homepage/besti.png'
              alt='BST'
              width={360}
              height={150}
              className='absolute inset-0 z-20 mx-auto max-h-[150px]'
            />
            <Typography
              variant='h2'
              weight='bold'
              font='LemonMilk'
              className='z-10 text-[#212E32] max-lg:text-[28px]'
            >
              BST BST BST
            </Typography>
          </div>

          <div
            className={` ${hovered2 ? 'opacity-100' : 'opacity-0'} z-20 flex items-center justify-center transition-opacity`}
          >
            <div className='mr-auto flex h-[150px] w-full items-center justify-between gap-12 bg-[#3BD09A]'>
              <div className='flex items-center gap-6'>
                <NextImage
                  src='/homepage/hbesti.png'
                  alt='BST'
                  width={233}
                  height={150}
                  className='ml-12 max-h-[150px]'
                />

                <Typography
                  variant='h5'
                  weight='bold'
                  font='LemonMilk'
                  className='text-[#212E32]'
                >
                  BOOTCAMP OF SEMINAR <br />
                  AND TECHNOLOGY
                </Typography>
              </div>
              <div className='mr-24'>
                <Typography
                  variant='h4'
                  weight='bold'
                  font='LemonMilk'
                  className='text-[#212E32]'
                >
                  <BsArrowRightCircle className='animate-bounce' />
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </UnstyledLink>

      {/*NLC*/}
      <UnstyledLink href='/nlc'>
        <div
          className='relative ml-auto mt-6 h-[150px] w-4/5 bg-[#FFCF55]'
          onMouseEnter={() => setHovered3(true)}
          onMouseLeave={() => setHovered3(false)}
          data-aos='fade-left'
        >
          <div
            className={`absolute inset-0 ${hovered3 ? 'opacity-0' : 'opacity-100'} z-20 flex items-center justify-center transition-opacity`}
          >
            <NextImage
              src='/homepage/leci.png'
              alt='NLC'
              width={272}
              height={150}
              className='absolute inset-0 z-20 mx-auto'
            />
            <Typography
              variant='h2'
              weight='bold'
              font='LemonMilk'
              className='z-10 text-[#221F19] max-lg:text-[28px]'
            >
              NLC NLC NLC
            </Typography>
          </div>

          <div
            className={` ${hovered3 ? 'opacity-100' : 'opacity-0'} z-10 flex items-center justify-center transition-opacity`}
          >
            <div className='ml-auto flex h-[150px] w-full items-center justify-between bg-[#FFCF55]'>
              <div className='flex items-center gap-6'>
                <NextImage
                  src='/homepage/hleci.png'
                  alt='NLC'
                  width={281}
                  height={222}
                  className='ml-6 h-full'
                />

                <Typography
                  variant='h5'
                  weight='bold'
                  font='LemonMilk'
                  className='text-[#221F19]'
                >
                  NATIONAL LOGIC COMPETITION
                </Typography>
              </div>
              <div className='mr-24'>
                <Typography
                  variant='h4'
                  weight='bold'
                  font='LemonMilk'
                  className='cursor-pointer text-[#221F19]'
                >
                  <BsArrowRightCircle className='animate-bounce' />
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </UnstyledLink>

      {/*Reeva*/}
      <UnstyledLink href='/reeva'>
        <div
          className='relative mr-auto mt-6 h-[150px] w-4/5 bg-[#54C4DB]'
          onMouseEnter={() => setHovered4(true)}
          onMouseLeave={() => setHovered4(false)}
          data-aos='fade-right'
        >
          <div
            className={`absolute inset-0 ${hovered4 ? 'opacity-0' : 'opacity-100'} z-20 flex items-center justify-center transition-opacity`}
          >
            <NextImage
              src='/homepage/riva.png'
              alt='Reeva'
              width={360}
              height={150}
              className='absolute inset-0 z-20 mx-auto max-h-[150px]'
            />
            <Typography
              variant='h2'
              weight='bold'
              font='LemonMilk'
              className='z-10 text-[#393C50] max-lg:text-[28px]'
            >
              REEVA REEVA REEVA
            </Typography>
          </div>

          <div
            className={` ${hovered4 ? 'opacity-100' : 'opacity-0'} z-20 flex items-center justify-center transition-opacity`}
          >
            <div className='mr-auto flex h-[150px] w-full items-center justify-between gap-12 bg-[#54C4DB]'>
              <div className='flex items-center gap-6'>
                <NextImage
                  src='/homepage/hriva.png'
                  alt='Reeva'
                  width={300}
                  height={150}
                  className='ml-3 max-h-[150px]'
                />

                <Typography
                  variant='h6'
                  weight='bold'
                  font='LemonMilk'
                  className='text-[#393C50]'
                >
                  REVOLUTIONARY ENTERTAINMENT <br /> AND EXPO WITH VARIOUS ARTS
                </Typography>
              </div>
              <div className='mr-24'>
                <Typography
                  variant='h4'
                  weight='bold'
                  font='LemonMilk'
                  className='text-[#393C50]'
                >
                  <BsArrowRightCircle className='animate-bounce' />
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </UnstyledLink>
    </main>
  );
}
